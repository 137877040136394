<template>
  <template v-for="heroSection in item.fields.heroSections">
    <component
      :is="getHeroSectionComponent(heroSection)"
      v-if="getHeroSectionComponent(heroSection)"
      :key="heroSection.sys.id"
      :item="heroSection"
      :color-mode="pageTheme"
    />
  </template>

  <DownloadsDocumentsDownloadCenterSearchbar v-if="isDownloadCenter" />
  <DownloadsSoftwareDownloadCenterSearchbar v-if="isSoftwareCenter" />
  <SearchInput
    v-if="searchTechnicalComponent"
    :item="searchTechnicalComponent"
  />
</template>

<script setup lang="ts">
import type { Entry } from 'contentful';
import type {
  IContentPage,
  IContentPageMultiLocale,
  IProductSeriesPage,
  ISolutionsArticlePage,
  ITechnicalComponent
} from '~/lib/ContentfulService';
import { pageToDefaultThemeMap } from '~/utils/pageToDefaultThemeMap';
import SearchInput from '~/components/lib/Search/SearchInput.vue';

interface Props {
  item:
    | IContentPage
    | ISolutionsArticlePage
    | IContentPageMultiLocale
    | IProductSeriesPage;
}

const props = defineProps<Props>();

const pageTheme = computed(
  () =>
    props.item.fields.metadata?.fields?.pageTheme ??
    pageToDefaultThemeMap[props.item.sys.contentType.sys.id]
);

// XXX: there might be a more elegant solution to this.
// @see https://gcp.baslerweb.com/jira/browse/DBP-930
const isDownloadCenter = computed(() =>
  props.item?.fields?.contentSection?.some((item) => {
    if (item?.sys?.contentType?.sys?.id === 'technicalComponent') {
      return (item as ITechnicalComponent).fields?.type === 'documentCenter';
    }

    return false;
  })
);

const searchTechnicalComponent = computed(
  () =>
    (props.item?.fields?.contentSection?.filter((item) => {
      if (item?.sys?.contentType?.sys?.id === 'technicalComponent') {
        return (item as ITechnicalComponent).fields?.type === 'searchSection';
      }

      return false;
    })[0] as ITechnicalComponent) ?? null
);

const isSoftwareCenter = computed(() =>
  props.item?.fields?.contentSection?.some((item) => {
    if (item?.sys?.contentType?.sys?.id === 'technicalComponent') {
      return (item as ITechnicalComponent).fields?.type === 'softwareCenter';
    }

    return false;
  })
);

function getHeroSectionComponent(entry?: Entry<unknown>) {
  switch (entry?.sys.contentType?.sys.id) {
    case 'experience':
      return 'ContentfulExperience';
    case 'multiExperience':
      return 'ContentfulMultiExperience';
    case 'heroArticleSection':
      return 'ContentfulHeroArticleSection';
    case 'heroProductSection':
      return 'ContentfulHeroProductSection';
    case 'heroOverviewSection':
      return 'ContentfulHeroOverviewSection';
  }
}
</script>
